import { createActionCreator } from '../utils/redux';

// action type constants
export const SET_ZOOM = 'SET_ZOOM';
export const SET_THEME = 'SET_THEME';
export const SET_LEVEL = 'SET_LEVEL';
export const SET_SELECTION = 'SET_SELECTION';
export const SET_SEARCH_INPUT = 'SET_SEARCH_INPUT';
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';

// actionCreators
export const setZoom = (zoom) => createActionCreator(SET_ZOOM, zoom);
export const setTheme = (theme) => createActionCreator(SET_THEME, theme);
export const setLevel = (level) => createActionCreator(SET_LEVEL, level);
export const setSelection = (level) => createActionCreator(SET_SELECTION, level);
export const setSearchInput = (input) => createActionCreator(SET_SEARCH_INPUT, input);
export const setSearchResults = (results) => createActionCreator(SET_SEARCH_RESULTS, results);

const initialState = {
  zoom: 0,
  theme: 'Service Coverage 100/20',
  level: 'Municipality',
  selection: null,
  searchInput: '',
  searchResults: [],
};

// reducer
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ZOOM:
      state.zoom = payload;
      return;
    case SET_THEME:
      state.theme = payload;
      return;
    case SET_LEVEL:
      state.level = payload;
      return;
    case SET_SELECTION:
      state.selection = payload;
      return;
    case SET_SEARCH_INPUT:
      state.searchInput = payload;
      return;
    case SET_SEARCH_RESULTS:
      state.searchResults = payload;
      return;
    default:
      return state;
  }
};
