// action types
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

// reducer with initial state
const initialState = {
  open: false,
  type: '',
  data: {},
  confirmAction: null,
};

// actionCreators
export const openModal = (payload) => ({ type: OPEN_MODAL, payload });
export const closeModal = () => ({ type: CLOSE_MODAL });

// selectors

// reducer
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case OPEN_MODAL: {
      const { type, title, body, data, confirmAction, modalData, fullScreen } = payload;
      return { ...state, open: true, type, title, body, confirmAction, modalData, data, fullScreen };
    }
    case CLOSE_MODAL:
      return initialState;
    default:
      return state;
  }
};
