import { put, takeEvery } from 'redux-saga/effects';
import { GET_USER_DETAILS, setUserDetails } from '../reducers/AdminUserMgtReducer';

export function* getSetUserDetails() {
  try {
    const data = yield fetch('/api/user').then((res) => res.json());
    yield put(setUserDetails(data));
  } catch (e) {
    console.error('Error getting user details', e);
  }
}

export default function* watch() {
  yield takeEvery(GET_USER_DETAILS, getSetUserDetails);
}
