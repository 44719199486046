// action type constants
export const GET_STATEWIDE_DATA = 'GET_STATEWIDE_DATA';
export const SET_STATEWIDE_DATA = 'SET_STATEWIDE_DATA';

// actionCreators
export const getStatewideData = () => ({ type: GET_STATEWIDE_DATA });
export const setStatewideData = (payload) => ({ type: SET_STATEWIDE_DATA, payload });

const initialState = {};

// reducer
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_STATEWIDE_DATA:
      state = payload;
      return state;
    default:
      return state;
  }
};
