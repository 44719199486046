import { takeLatest, put, select } from 'redux-saga/effects';
import { GET_STATEWIDE_DATA, setStatewideData } from '../reducers/statewideData';
import { selectCacheVersion } from '../reducers/cacheVersion';

import loading from '../utils/loading';

function* GetStatewideData() {
  const loadingMsg = 'Fetching statewide Data...';
  loading.start(loadingMsg);
  try {
    const cacheVersion = yield select(selectCacheVersion);
    const resp = yield fetch(`/api/feature/state/1?v=${cacheVersion}`);
    if (resp.status > 299) throw new Error('Invalid Response Code');
    const data = yield resp.json();
    yield put(setStatewideData(data));
  } catch (e) {
    console.log('ERROR fetching statewide data', e);
    // handle error
  } finally {
    loading.end(loadingMsg);
  }
}

export default function* watch() {
  yield takeLatest(GET_STATEWIDE_DATA, GetStatewideData);
}
