// action types
export const OPEN_SNACKBAR = 'OPEN_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';

// reducer with initial state
const initialState = {
  open: false,
  severity: 'info',
  message: '',
};

// actionCreators
export const openSnackbar = ({ message, severity = 'info' }) => ({ type: OPEN_SNACKBAR, message, severity });
export const closeSnackbar = () => ({ type: CLOSE_SNACKBAR });

// reducer
export default (state = initialState, { type, message, severity }) => {
  switch (type) {
    case OPEN_SNACKBAR: {
      state.open = true;
      state.message = message;
      state.severity = severity;
      return;
    }
    case CLOSE_SNACKBAR:
      return initialState;
    default:
      return state;
  }
};

// selectors
export const getSnackbarDetails = (state) => state.snackbar;
