/* eslint-disable import/no-cycle */
import { fork, all } from 'redux-saga/effects';

import getStatewideData from './getStatewideData';
import getSetCacheVersion from './getSetCacheVersion';
import getSetCurrentUser from './appSetup/getSetCurrentUser';
import getSetUserDetails from './getSetUserDetails';
import getSetPendingRequests from './getSetPendingRequests';
import getSetPendingInvites from './getSetPendingInvites';
import watchLockUserAccount from './adminActions/toggleLockUserSaga';
import watchAccountRequest from './adminActions/acceptOrRejectUserRequests';
import watchEditAction from './adminActions/editUserDetailsSaga';
import deleteUserAccountSaga from './adminActions/deleteUserSaga';
import withdrawUserInviteSaga from './adminActions/withdrawUserInvite';

// import mapSetup from './map/mapSetup';

const sagas = [
  getStatewideData,
  getSetCacheVersion,
  getSetCurrentUser,
  getSetUserDetails,
  getSetPendingRequests,
  watchLockUserAccount,
  watchAccountRequest,
  watchEditAction,
  deleteUserAccountSaga,
  getSetPendingInvites,
  withdrawUserInviteSaga,
];

function* globalSagas() {
  const globalSagasForks = sagas.map((saga) => fork(saga));
  yield all([...globalSagasForks]);
}

export default globalSagas;
