import { call, takeEvery, put } from 'redux-saga/effects';
import { getUserDetails, DELETE_USER_ACCOUNT } from '../../reducers/AdminUserMgtReducer';
import { openSnackbar } from '../../reducers/snackbar';
import { closeModal } from '../../reducers/modal';

export function* deleteUserAccount(action) {
  const { userId } = action.payload;

  try {
    const res = yield call(fetch, `/api/user/${userId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (res.ok) {
      yield put(openSnackbar({ message: 'User deleted', severity: 'success' }));
      yield put(closeModal());
      yield put(getUserDetails());
    } else if (!res.ok) {
      throw new Error('Failed to delete User account');
    }
  } catch (error) {
    yield put(
      openSnackbar({ message: error.message, severity: 'error' }),
    );
  }
}

export default function* watch() {
  yield takeEvery(DELETE_USER_ACCOUNT, deleteUserAccount);
}
