// action type constants
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const LOGOUT = 'LOGOUT';

// actionCreators
export const setCurrentUser = (user) => ({ type: SET_CURRENT_USER, user });
export const getCurrentUser = () => ({ type: GET_CURRENT_USER });
export const logout = () => ({ type: LOGOUT });

const initialState = {
  role: '',
  name: '',
  org: '',
};

// reducer
export default (state = initialState, { type, user }) => {
  switch (type) {
    case SET_CURRENT_USER:
      return user;
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

// selectors
export const isUserSignedIn = (state) => Boolean(state.currentUser.name);
export const isUserAdmin = (state) => state.currentUser.role === 'admin';
export const getUsername = (state) => state.currentUser.name;
export const getOrg = (state) => state.currentUser.org;
