import { call, takeEvery, put } from 'redux-saga/effects';
import { getPendingUserRequests, ACCEPT_ACCOUNT_REQUEST, REJECT_ACCOUNT_REQUEST } from '../../reducers/AdminUserMgtReducer';
import { openSnackbar } from '../../reducers/snackbar';

export function* acceptUserAccount(action) {
  const { reqId } = action.payload;
  try {
    const res = yield call(fetch, `/api/request/accept/${reqId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!res.ok) {
      throw new Error('Something went wrong while accepting user account request');
    } else if (res.ok) {
      yield put(getPendingUserRequests());
      yield put(
        openSnackbar({ message: 'User request accepted successfully', severity: 'success' }),
      );
    }
  } catch (error) {
    yield put(
      openSnackbar({ message: error.message, severity: 'error' }),
    );
  }
}

export function* rejectUserAccount(action) {
  const { reqId } = action.payload;
  try {
    const res = yield call(fetch, `/api/request/reject/${reqId}`, {
      method: 'PUT',
    });
    if (!res.ok) {
      throw new Error('Something went wrong while rejecting user account request');
    } else if (res.ok) {
      yield put(getPendingUserRequests());
      yield put(
        openSnackbar({ message: 'User request Denied', severity: 'warning' }),
      );
    }
  } catch (error) {
    yield put(
      openSnackbar({ message: error.message, severity: 'error' }),
    );
  }
}

export default function* watch() {
  yield takeEvery(ACCEPT_ACCOUNT_REQUEST, acceptUserAccount);
  yield takeEvery(REJECT_ACCOUNT_REQUEST, rejectUserAccount);
}
