import { put, takeEvery } from 'redux-saga/effects';
import { GET_PENDING_REQUESTS, setPending } from '../reducers/AdminUserMgtReducer';

export function* getSetPendingRequests() {
  try {
    const data = yield fetch('/api/request').then((res) => res.json());
    yield put(setPending(data));
  } catch (e) {
    console.error('Error getting pending requests', e);
  }
}

export default function* watch() {
  yield takeEvery(GET_PENDING_REQUESTS, getSetPendingRequests);
}
