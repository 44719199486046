import { put, takeEvery } from 'redux-saga/effects';

import { setCacheVersion, GET_CACHE_VERSION } from '../reducers/cacheVersion';
import loading from '../utils/loading';

export function* getSetCacheVersion() {
  const loadingMsg = 'fetching cache version';
  yield loading.start(loadingMsg);
  try {
    const resp = yield fetch('/api/cacheversion');
    const { cacheVersion } = yield resp.json();
    yield put(setCacheVersion(cacheVersion));
    // return cacheVersion;
  } catch (e) {
    console.error('Error getting cache version', e);
  } finally {
    yield loading.end(loadingMsg);
  }
}

export default function* watch() {
  yield takeEvery(GET_CACHE_VERSION, getSetCacheVersion);
}
