import { call, takeEvery, select, put } from 'redux-saga/effects';
import { UPDATE_USER_DETAILS, FETCH_EDIT_DETAILS, setEditDetails } from '../../reducers/userEditForm';
import { getUserDetails } from '../../reducers/AdminUserMgtReducer';
import { closeModal } from '../../reducers/modal';
import { openSnackbar } from '../../reducers/snackbar';

export function* editUserDetails() {
  const { user_id, name, organization, email, role } = yield (select((state) => state.userEditForm.fields));
  console.log(yield (select((state) => state.userEditForm.fields)));
  // check if the role field has changed in the userEditForm state
  const isRoleChanged = yield select((state) => state.userEditForm.changedFields.has('role'));

  // check if the name or org or email field has changed in the userEditForm state
  const isNameOrOrganizationOrEmailChanged = yield select((state) => state.userEditForm.changedFields.has('name')
  || state.userEditForm.changedFields.has('organization') || state.userEditForm.changedFields.has('email'));

  try {
    if (isRoleChanged) {
      const editUserIsGivenAdminRole = (role === 'admin');

      // Grant or revoke admin role based on the user's selection
      const endpoint = editUserIsGivenAdminRole
        ? `/api/user/grant/admin/${user_id}`
        : `/api/user/revoke/admin/${user_id}`;

      const res = yield call(fetch, endpoint, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!res.ok) {
        throw new Error('Failed to update user role');
      }
    }
    if (isNameOrOrganizationOrEmailChanged) {
    // Update other user details
      const res = yield call(fetch, `/api/user/${user_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
          organization,
          email,
        }),
      });

      if (res.ok) {
        yield put(getUserDetails());
        yield put(closeModal());
        yield put(
          openSnackbar({ message: 'User details updated', severity: 'success' }),
        );
      } else if (!res.ok) {
        throw new Error('Failed to update user details');
      }
    }
  } catch (error) {
    yield put(
      openSnackbar({ message: error.message, severity: 'error' }),
    );
  }
}

export function* fetchEditDetails(action) {
  const { id } = action.payload;
  try {
    const userDetails = yield fetch(`/api/user/${id}`).then((res) => res.json());
    yield put(setEditDetails(userDetails));
  } catch (e) {
    yield put(
      openSnackbar({ message: e.message || 'Error getting user details', severity: 'error' }),
    );
  }
}

export default function* watch() {
  yield takeEvery(UPDATE_USER_DETAILS, editUserDetails);
  yield takeEvery(FETCH_EDIT_DETAILS, fetchEditDetails);
}
