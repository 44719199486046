import store from '../store';
import { loadingStart, loadingEnd } from '../reducers/loading';

export default {
  start(loadingMsg) {
    return store.dispatch(loadingStart(loadingMsg));
  },
  end(loadingMsg) {
    return store.dispatch(loadingEnd(loadingMsg));
  },
};
