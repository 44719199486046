import { put, call, takeEvery, select } from 'redux-saga/effects';
import { lockUserSuccess, LOCK_USER } from '../../reducers/AdminUserMgtReducer';
import { openSnackbar } from '../../reducers/snackbar';

export function* lockUser(userId) {
  try {
    const res = yield call(fetch, `/api/user/lock/${userId}`, {
      method: 'PUT',
    });
    if (res.ok) {
      yield put(lockUserSuccess(userId));
      yield put(openSnackbar({ message: 'User account locked', severity: 'warning' }));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* unlockUser(userId) {
  try {
    const res = yield call(fetch, `/api/user/unlock/${userId}`, {
      method: 'PUT',
    });
    if (res.ok) {
      yield put(lockUserSuccess(userId));
      yield put(openSnackbar({ message: 'User account unlocked', severity: 'success' }));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* toggleLockUserAccount(action) {
  const { userId } = action.payload;
  const locked = yield select((state) => state.actionsReducer.userDetails.find((user) => user.user_id === userId).locked);
  // if locked, unlock else lock
  if (locked) {
    yield call(unlockUser, userId);
  } else {
    yield call(lockUser, userId);
  }
}

export default function* watch() {
  yield takeEvery(LOCK_USER, toggleLockUserAccount);
}
