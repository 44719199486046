// Action Types
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const LOCK_USER = 'LOCK_USER';
export const LOCK_USER_SUCCESS = 'LOCK_USER_SUCCESS';
export const EDIT_USER_ROLE = 'EDIT_USER_ROLE';
export const DELETE_USER_ACCOUNT = 'DELETE_USER_ACCOUNT';
export const SET_PENDING_REQUESTS = 'SET_PENDING_REQUESTS';
export const GET_PENDING_REQUESTS = 'GET_PENDING_REQUESTS';
export const ACCEPT_ACCOUNT_REQUEST = 'ACCEPT_ACCOUNT_REQUEST';
export const REJECT_ACCOUNT_REQUEST = 'REJECT_ACCOUNT_REQUEST';
export const SUCCESS_ACCOUNT_REQUEST = 'SUCCESS_ACCOUNT_REQUEST';
export const GET_PENDING_INVITES = 'GET_PENDING_INVITES';
export const SET_PENDING_INVITES = 'SET_PENDING_INVITES';
export const WITHDRAW_INVITE = 'WITHDRAW_INVITE';

// Action Creators

export const getUserDetails = () => ({ type: GET_USER_DETAILS });

export const setUserDetails = (data) => ({ type: SET_USER_DETAILS, payload: data });

export const lockUser = (userId) => ({
  type: LOCK_USER,
  payload: { userId },
});

export const lockUserSuccess = (userId) => ({
  type: LOCK_USER_SUCCESS,
  payload: { userId },
});

export const editUserRole = (userId, newRole) => ({
  type: EDIT_USER_ROLE,
  payload: { userId, newRole },
});

export const deleteUserAccount = (userId) => ({
  type: DELETE_USER_ACCOUNT,
  payload: { userId },
});

export const getPendingUserRequests = () => ({ type: GET_PENDING_REQUESTS });

export const setPending = (data) => ({ type: SET_PENDING_REQUESTS, payload: data });

export const getPendingInvites = () => ({ type: GET_PENDING_INVITES });

export const setPendingInvites = (data) => ({ type: SET_PENDING_INVITES, payload: data });

export const withdrawInvite = (actionId) => ({
  type: WITHDRAW_INVITE, payload: { actionId },
});

export const acceptAccountRequest = (reqId) => ({
  type: ACCEPT_ACCOUNT_REQUEST,
  payload: { reqId },
});

export const rejectAccountRequest = (reqId) => ({
  type: REJECT_ACCOUNT_REQUEST,
  payload: { reqId },
});

// reducer

const initialState = {
  userDetails: [],
  requests: [],
  invites: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER_DETAILS:
      state.userDetails = payload;
      return;
    case SET_PENDING_REQUESTS:
      state.requests = payload;
      return;
    case SET_PENDING_INVITES:
      state.invites = payload;
      return;
    case LOCK_USER_SUCCESS:
      state.userDetails = state.userDetails.map((user) => {
        if (user.user_id === payload.userId) {
          user.locked = !user.locked;
        }
        return user;
      });
      return;
    default:
      return state;
  }
};

// selectors

export const getDetails = (state) => state.actionsReducer.userDetails;
export const getPendingRequests = (state) => state.actionsReducer.requests;
