import { put, takeEvery } from 'redux-saga/effects';

import { setCurrentUser, GET_CURRENT_USER } from '../../reducers/currentUser';
import loading from '../../utils/loading';

export function* getSetCurrentUser() {
  const loadingMsg = 'Checking for current user';
  yield loading.start(loadingMsg);
  try {
    const resp = yield fetch('/api/currentuser');
    // if (resp.status === 401) {
    //   window.location = '/login';
    // }
    const currentUser = yield resp.json();
    yield put(setCurrentUser(currentUser));
    return currentUser;
  } catch (e) {
    console.error('Error getting current user info', e);
  } finally {
    yield loading.end('current user check finished');
  }
}

export default function* watch() {
  yield takeEvery(GET_CURRENT_USER, getSetCurrentUser);
}
