// action type constants
export const GET_CACHE_VERSION = 'GET_CACHE_VERSION';
export const SET_CACHE_VERSION = 'SET_CACHE_VERSION';

// actionCreators
export const getCacheVersion = () => ({ type: GET_CACHE_VERSION });
export const setCacheVersion = (payload) => ({ type: SET_CACHE_VERSION, payload });

// selectors
export const selectCacheVersion = (state) => state.cacheVersion;

const initialState = '';

// reducer
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CACHE_VERSION:
      state = payload;
      return state;
    default:
      return state;
  }
};
