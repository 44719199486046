import { combineReducers as oldCombineReducers } from 'redux';
import { produce, enableMapSet } from 'immer';

enableMapSet();

export const createActionCreator = (actionType, payload) => ({ type: actionType, payload });

export const createReducer = (fn) => (state, action) => produce(state, (newState) => fn(newState, action));

export const combineReducers = (reducers) => {
  const out = {};
  const keys = Object.keys(reducers);
  keys.sort();
  for (const name of keys) {
    out[name] = createReducer(reducers[name]);
  }
  return oldCombineReducers(out);
};
