import { combineReducers } from '../utils/redux';

// import appConfig from './appConfig';
import currentUser from './currentUser';
import loading from './loading';
import mainControls from './mainControls';
// import mapRefs from './mapRefs';
import modal from './modal';
import snackbar from './snackbar';
import statewideData from './statewideData';
import cacheVersion from './cacheVersion';
import actionsReducer from './AdminUserMgtReducer';
import userEditForm from './userEditForm';

const reducers = combineReducers({
  // appConfig,
  currentUser,
  loading,
  mainControls,
  actionsReducer,
  userEditForm,
  // mapRefs,
  modal,
  snackbar,
  statewideData,
  cacheVersion,
});

export default reducers;
