// import { useTheme } from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { Routes, Route, Navigate } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
// import { useEffect } from 'react';

// import Modal from './components/modal/Modal';
// import DesktopLayout from './components/layout/desktop/DesktopLayout';
// import MapPageMobile from './components/layout/mobile/MapPageMobile';
// import StatsPageMobile from './components/layout/mobile/StatsPageMobile';
// import UserPage from './components/adminPages/UserPage';
// import SnackbarMessage from './components/utility/SnackbarMessage';
// import RequestPage from './components/adminPages/RequestPage';
// import InvitePage from './components/adminPages/InvitePage';
// import AdminLayout from './components/adminPages/AdminLayout';
// import InfoPageMobile from './components/layout/mobile/InfoPageMobile';
// import HelpPageMobile from './components/layout/mobile/HelpPageMobile';
// import RedirectOnLoad from './components/utility/RedirectOnLoad';
// import Show from './components/utility/Show';
// import { getCacheVersion } from './reducers/cacheVersion';
// import { getStatewideData } from './reducers/statewideData';
// import { getCurrentUser } from './reducers/currentUser';
import ComingSoonPage from './components/utility/ComingSoon';

function App() {
  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  // const cacheVersion = useSelector(({ cacheVersion }) => cacheVersion);
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   window.gtag('event', 'pageview', {
  //     event_category: 'pageview',
  //     event_label: 'pageview',
  //   });
  //   dispatch(getCacheVersion());
  //   dispatch(getCurrentUser());
  // }, []);

  // useEffect(() => {
  //   if (cacheVersion) dispatch(getStatewideData());
  // }, [cacheVersion]);

  return <ComingSoonPage />;
  // <>
  //   <SnackbarMessage />
  //   <Modal />
  //   <Routes>
  //     <Route path="/" element={<RedirectOnLoad />} />
  //     <Route
  //       path="map"
  //       element={(
  //         <>
  //           <Show test={!isMobile}>
  //             <DesktopLayout />
  //           </Show>
  //           <Show test={isMobile}>
  //             <MapPageMobile />
  //           </Show>
  //         </>
  //       )}
  //     />
  //     <Route
  //       path="stats"
  //       element={(
  //         <Show test={isMobile}>
  //           <StatsPageMobile />
  //         </Show>
  //       )}
  //     />
  //     <Route
  //       path="info"
  //       element={(
  //         <Show test={isMobile}>
  //           <InfoPageMobile />
  //         </Show>
  //       )}
  //     />
  //     <Route
  //       path="help"
  //       element={(
  //         <Show test={isMobile}>
  //           <HelpPageMobile />
  //         </Show>
  //       )}
  //     />
  //     <Route
  //       path="admin"
  //       element={(
  //         <AdminLayout />
  //       )}
  //     >
  //       <Route path="" element={<Navigate to="users" />} />
  //       <Route path="users" element={<UserPage title="Users" />} />
  //       <Route path="requests" element={<RequestPage title="Pending Requests" />} />
  //       <Route path="invite" element={<InvitePage title="Pending Invites" />} />
  //     </Route>
  //   </Routes>
  // </>
}

export default App;
