import { takeEvery, call, put } from 'redux-saga/effects';
import { WITHDRAW_INVITE, getPendingInvites } from '../../reducers/AdminUserMgtReducer';
import { openSnackbar } from '../../reducers/snackbar';

export function* withdrawUserInvite(action) {
  const { actionId } = action.payload;
  console.log('withdrawUserInvite', actionId);
  try {
    const res = yield call(fetch, `/api/invite/withdraw/${actionId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (res.ok) {
      yield put(getPendingInvites());
      yield put(
        openSnackbar({ message: 'Invite withdrawn sucessfully', severity: 'success' }),
      );
    } else if (!res.ok) {
      throw new Error('Failed to withdraw invite');
    }
  } catch (error) {
    yield put(
      openSnackbar({ message: error.message, severity: 'error' }),
    );
  }
}

export default function* watch() {
  yield takeEvery(WITHDRAW_INVITE, withdrawUserInvite);
}
