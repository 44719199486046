import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CountdownTimer from './CountdownTimer';

const ComingSoonPage = () => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    height="100vh"
    bgcolor="background.default"
    color="text.primary"
  >
    <Typography variant="h2" gutterBottom>
      Coming Soon
    </Typography>
    <Typography variant="h5" gutterBottom>
      A new Massachusetts Broadband Map is under development and will be released on June 10th in association with the BEAD challenge process
    </Typography>
    <CountdownTimer targetDate="2024-06-10T00:00:00" />
  </Box>
);

export default ComingSoonPage;
