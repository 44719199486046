import { put, takeEvery } from 'redux-saga/effects';
import { GET_PENDING_INVITES, setPendingInvites } from '../reducers/AdminUserMgtReducer';

export function* getSetPendingInvites() {
  try {
    const data = yield fetch('/api/invite').then((res) => res.json());
    yield put(setPendingInvites(data));
  } catch (e) {
    console.error('Error getting pending invites', e);
  }
}

export default function* watch() {
  yield takeEvery(GET_PENDING_INVITES, getSetPendingInvites);
}
