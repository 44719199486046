// action type constants
const LOADING_START = 'LOADING_START';
const LOADING_END = 'LOADING_END';

// actionCreators
export const loadingStart = (msg) => ({ type: LOADING_START, msg });
export const loadingEnd = (msg) => ({ type: LOADING_END, msg });

const initialState = {
  msgs: ['Loading Application...'/*, 'Testing'*/], // uncomment testing if you need to do styling for the loading screen
};

// reducer
export default (state = initialState, { type, msg }) => {
  switch (type) {
    case LOADING_START: {
      state.msgs.push(msg);
      return state;
    }
    case LOADING_END: {
      const idx = state.msgs.indexOf(msg);
      if (idx > -1) {
        state.msgs.splice(idx, 1);
      }
      return;
    }
    default:
      return state;
  }
};
