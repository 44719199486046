// action type constants
export const SET_EDIT_DETAILS = 'SET_EDIT_DETAILS';
export const FETCH_EDIT_DETAILS = 'FETCH_EDIT_DETAILS';
export const RESET_EDIT_FORM = 'RESET_EDIT_FORM';
export const UPDATE_USER_FORM_VALUE = 'UPDATE_FORM_VALUE';
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';
export const SET_UPDATE_SUCCESS = 'SET_UPDATE_SUCCESS';
export const SET_UPDATE_ERROR = 'SET_UPDATE_ERROR';

// actionCreators
export const setEditDetails = (userDetails) => ({ type: SET_EDIT_DETAILS, payload: userDetails });
export const fetchEditDetails = (id) => ({ type: FETCH_EDIT_DETAILS, payload: { id } });
export const resetEditForm = () => ({ type: RESET_EDIT_FORM });
export const updateUserDetailsFormValue = ({ key, value }) => ({ type: UPDATE_USER_FORM_VALUE, payload: { key, value } });
export const updateUserDetails = () => ({ type: UPDATE_USER_DETAILS });
export const setUpdateSuccess = (success) => ({ type: SET_UPDATE_SUCCESS, payload: success });
export const setUpdateError = (error) => ({ type: SET_UPDATE_ERROR, payload: error });

// reducer
const initialState = {
  fields: {
    userId: 0,
    name: '',
    organization: '',
    role: 'user',
    email: '' },
  updateSuccess: false,
  updateError: false,
  changedFields: new Set(),
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_EDIT_DETAILS:
      state.fields = payload;
      return;
    case UPDATE_USER_FORM_VALUE: {
      const { key, value } = payload;
      state.fields[key] = value;
      state.changedFields.add(key);
      return;
    }
    case SET_UPDATE_SUCCESS:
      state.updateSuccess = payload;
      return;
    case SET_UPDATE_ERROR:
      state.updateError = payload;
      return;
    case RESET_EDIT_FORM:
      return initialState;
    default:
      return state;
  }
};

// selectors
export const getEditDetails = (state) => state.userEditForm.fields;
